import { forwardRef } from 'react';
import { FormControl, FormLabel, InputBase, Theme, FormHelperText, InputBaseProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  formControl: {
    '&.MuiFormControl-root': {
      width: '100%',
      marginBottom: spacing(2),
    },
  },
  formLabel: {
    '&.MuiFormLabel-root': {
      textAlign: 'left',
      color: 'black',
      fontSize: '0.875rem',
      marginBottom: 5,
      paddingLeft: spacing(0.2),
    },
  },
  inputStyle: {
    height: '44px',
    border: `1px solid ${palette.grey[400]}`,
    fontSize: '1rem',
    borderRadius: 8,
    backgroundColor: 'white',
    padding: '10px',
  },
  inputWithError: {
    border: `1px solid ${palette.error.main}`,
  },
  helperTextStyle: {
    '&.MuiFormHelperText-root': {
      paddingLeft: spacing(0.2),
      fontSize: '0.875rem',
    },
  },
  helperTextWithError: {
    '&.MuiFormHelperText-root': {
      color: palette.error.main,
    },
  },
}));

type CustomInputProps = {
  label?: string;
  helperText?: string;
} & InputBaseProps;

const Input = forwardRef<HTMLInputElement, CustomInputProps>(({ label, helperText, ...inputProps }, ref) => {
  const { formControl, formLabel, inputStyle, inputWithError, helperTextStyle, helperTextWithError } = useStyles();
  const hasError = !!inputProps.error;

  return (
    <FormControl className={formControl} variant="standard">
      {label && <FormLabel className={formLabel}>{label}</FormLabel>}
      <InputBase
        inputRef={ref}
        fullWidth
        className={clsx(inputStyle, {
          [inputWithError]: hasError,
        })}
        {...inputProps}
      />
      {helperText && (
        <FormHelperText
          id={`${inputProps?.id}-text`}
          className={clsx(helperTextStyle, {
            [helperTextWithError]: hasError,
          })}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
});

export default Input;
