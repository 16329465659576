import { FC, ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Avatar } from 'components/atoms';

type AvatarCardProps = {
  details: {
    image?: string;
    title?: string;
    subtitle?: string;
    other?: ReactNode;
  };
  action?: ReactNode;
};

const AvatarCard: FC<AvatarCardProps> = ({ details, action }) => {
  const { image, title, subtitle, other } = details;
  return (
    <Box
      sx={{
        display: 'flex',
        height: 210,
        backgroundImage: `url(${process.env.REACT_APP_ASSETS_URL}/backgrounds/general_bg.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Stack pl={3} pt={3} sx={{ width: '80%' }}>
        <Avatar size={80} src={image} />

        {title && (
          <Typography sx={{ marginTop: 3, fontWeight: '600' }} variant="h5" color="white">
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="body1" color="white">
            {subtitle}
          </Typography>
        )}
        {other && <Box mt={5}>{other}</Box>}
      </Stack>

      {action && (
        <Box pt={3} sx={{ width: '20%', textAlign: 'center' }}>
          {action}
        </Box>
      )}
    </Box>
  );
};

export default AvatarCard;
