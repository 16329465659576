import { FC } from 'react';
import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  Theme,
  FormLabel,
  Typography,
} from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
      minWidth: 360,
      maxWidth: 600,
      textOverflow: 'ellipsis',
    },
  },
};

type SelectProps = MuiSelectProps & {
  options: { key: string; label: string }[];
  small?: boolean;
};

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  smallItem: {
    '& .MuiInputBase-formControl': {
      height: '40px',
      '& label, input, .MuiInputBase-input': {
        fontSize: '0.9em',
      },
    },
  },
  listItem: {
    whiteSpace: 'break-spaces',
  },
  selectStyle: {
    padding: '0px!important',
  },
  formLabel: {
    '&.MuiFormLabel-root': {
      textAlign: 'left',
      color: 'black',
      fontSize: '0.875rem',
      marginBottom: 5,
      paddingLeft: spacing(0.2),
    },
  },
  inputStyle: {
    width: '100%',
    height: '44px',
    border: `1px solid ${palette.grey[400]}`,
    fontSize: '1rem',
    borderRadius: 8,
    backgroundColor: 'white',
    padding: '10px',
  },
  placeholderStyle: {
    color: palette.grey[500],
  },
}));

const Select: FC<SelectProps> = ({ options, fullWidth, className, small, label: inputLabel, placeholder, ...rest }) => {
  const { listItem, smallItem, selectStyle, formLabel, inputStyle, placeholderStyle } = useStyles();

  return (
    <>
      <FormControl
        fullWidth={fullWidth}
        className={clsx(className, {
          [smallItem]: small,
        })}
      >
        {inputLabel && <FormLabel className={formLabel}>{inputLabel}</FormLabel>}
        <MuiSelect
          {...rest}
          renderValue={(selected) => {
            if (selected === '') {
              return <Typography className={placeholderStyle}>{placeholder || 'Select'}</Typography>;
            }

            return options.find((item) => item.key === selected)?.label;
          }}
          input={<OutlinedInput className={inputStyle} />}
          MenuProps={MenuProps}
          className={selectStyle}
          IconComponent={(props) => <ExpandMoreOutlined {...props} />}
        >
          {options?.map(({ key, label }) => (
            <MenuItem key={key} value={key}>
              <ListItemText primary={label} className={listItem} />
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </>
  );
};
export default Select;
