import { FC, ReactNode } from 'react';
import { ApolloProvider, ApolloLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';

interface Props {
  children: ReactNode;
}

const DataroomUrl = process.env.REACT_APP_APPSYNC_ENDPOINT_DATAROOM as string;
const region = process.env.REACT_APP_AWS_REGION as string;
const auth: AuthOptions = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken() as string,
};

const ErrorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    const gErrors = graphQLErrors.map(
      ({ message, locations, path }) => `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
    );

    if (process.env.NODE_ENV !== 'development') {
      Sentry.captureException(gErrors);
    }
  }
});

const dataroomLink = ApolloLink.from([
  ErrorLink as any,
  createAuthLink({
    url: DataroomUrl,
    region,
    auth,
  }),
  createSubscriptionHandshakeLink({
    url: DataroomUrl,
    region,
    auth,
  }),
]);

const directionalLink = dataroomLink;

const client = new ApolloClient({
  link: directionalLink,
  cache: new InMemoryCache({ addTypename: false }),
});

const Apollo: FC<Props> = ({ children }: Props) => <ApolloProvider client={client as any}>{children}</ApolloProvider>;

export default Apollo;
