import { FC } from 'react';
import { Avatar as AvatarMUI } from '@mui/material';
import { AVATAR_BACKGROUND_COLOR } from 'theme';
import getInitials from 'utils/getInitials';

type AvatarProps = {
  firstName?: string;
  lastName?: string;
  src?: string;
  color?: string;
  size?: number;
};

const Avatar: FC<AvatarProps> = ({ firstName, lastName, src, color = AVATAR_BACKGROUND_COLOR, size = 40 }) => {
  const avatarStyle = {
    fontSize: '16px',
    fontWeight: '600',
    width: `${size}px`,
    height: `${size}px`,
    backgroundColor: color,
  };

  if (src) {
    return <AvatarMUI src={src} alt="Avatar" style={{ border: 'none', width: `${size}px`, height: `${size}px` }} />;
  }

  if (!firstName && !lastName) {
    return <AvatarMUI sx={avatarStyle} />;
  }

  return <AvatarMUI sx={avatarStyle}>{getInitials(firstName || '', lastName || '')}</AvatarMUI>;
};

export default Avatar;
