import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';


const ContentLayout: FC = () => {

  return (
    <Box p={2}>
      <Outlet />
    </Box>
  );
};

export default ContentLayout;
