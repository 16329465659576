import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import getRandomAvatarColor from 'utils/getRandomAvatarColor';

type UserType = {
  avatar?: {
    bucket?: string;
    key?: string;
    region?: string;
    backgroundColor?: string;
  };
  email?: string;
  firstName?: string;
  id?: string;
  lastName?: string;
  phoneNumber?: string;
  address?: string;
  organization?: string;
  position?: string;
};

type UserAttributesType = {
  given_name?: string;
  family_name?: string;
  email?: string;
  phone_number?: string;
  address?: string;
  'custom:position'?: string;
  'custom:company_name'?: string;
  'custom:background_color'?: string;
};

interface AuthContextInterface {
  user: UserType;
  isSignedIn: boolean;
  isLoading: boolean;
  signIn: (username: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  forgotPasswordRequest: (email: string) => Promise<void>;
  forgotPasswordSubmit: (email: string, code: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, firstName: string, lastName: string, invitedBy: string) => Promise<void>;
  confirmSignUp: (email: string, code: string) => Promise<void>;
  resendSignUp: (email: string) => Promise<void>;
  changePassword: (password: string, newPassword: string) => Promise<void>;
  changeTemporaryPassword: (email: string, password: string, newPassword: string) => Promise<void>;
  updateUserAttributes: (attributes: UserAttributesType) => Promise<void>;
}

const AuthContext = createContext<AuthContextInterface | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<UserType>({});

  const currentAuthenticatedUser = async () => {
    await Auth.currentAuthenticatedUser();
    const { attributes } = await Auth.currentUserInfo();
    setUser({
      id: attributes.sub,
      lastName: attributes.family_name,
      firstName: attributes.given_name,
      email: attributes.email,
      phoneNumber: attributes.phone_number,
      address: attributes.address,
      position: attributes['custom:position'],
      organization: attributes['custom:company_name'],
      avatar: {
        backgroundColor: attributes['custom:background_color'],
      },
    });
  };

  const signIn = async (username: string, password: string) => {
    const data = await Auth.signIn(username, password);
    await currentAuthenticatedUser();

    setIsSignedIn(true);

    switch (data.authenticationFlowType) {
      case 'SMS_MFA':
      case 'SOFTWARE_TOKEN_MFA':
        // setError('MFA is not supported yet.');
        break;
      case 'NEW_PASSWORD_REQUIRED':
        navigate('/change-password');
        break;
      case 'MFA_SETUP':
        // setError('Multi factor authentication is required.');
        break;
      default:
        break;
    }
  };

  const signOut = async () => {
    await Auth.signOut();
    setUser({});
    setIsSignedIn(false);
  };

  const signUp = async (email: string, password: string, firstName: string, lastName: string, invitedBy: string) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        given_name: firstName,
        family_name: lastName,
        email,
        'custom:invited_by': invitedBy,
        'custom:background_color': getRandomAvatarColor(),
      },
    });
  };

  const confirmSignUp = async (email: string, code: string) => {
    await Auth.confirmSignUp(email, code);
  };

  const resendSignUp = async (email: string) => {
    await Auth.resendSignUp(email);
  };

  const changeTemporaryPassword = async (username: string, oldPassword: string, password: string) => {
    const userObject = await Auth.signIn(username, oldPassword);
    await Auth.completeNewPassword(userObject, password);
  };

  const changePassword = async (oldPassword: string, password: string) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, password);
  };

  const updateUserAttributes = async (attributes: UserAttributesType) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, attributes);
  };

  const loadCurrentUser = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      await currentAuthenticatedUser();
      setIsSignedIn(true);
    } catch (error) {
      setIsSignedIn(false);
    } finally {
      setIsLoading(false);
    }
  };

  const forgotPasswordRequest = async (email: string) => {
    await Auth.forgotPassword(email);
  };

  const forgotPasswordSubmit = async (userId: string, code: string, password: string) => {
    await Auth.forgotPasswordSubmit(userId, code, password);
  };

  useEffect(() => {
    loadCurrentUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isSignedIn,
        isLoading,
        signIn,
        signOut,
        forgotPasswordRequest,
        forgotPasswordSubmit,
        signUp,
        confirmSignUp,
        resendSignUp,
        changePassword,
        changeTemporaryPassword,
        updateUserAttributes,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextInterface => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
