import { FC } from 'react';
import { Breadcrumbs as BreadcrumbsMUI, Typography } from '@mui/material';
import { FiberManualRecordRounded } from '@mui/icons-material';

export interface BreadcrumbsProps {
  breadcrumbs: string[];
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs }) => (
  <BreadcrumbsMUI
    aria-label="breadcrumb"
    separator={<FiberManualRecordRounded htmlColor="#D9D9D9" style={{ fontSize: '12px' }} />}
  >
    {breadcrumbs.map((breadcrumb: string, index: number) => (
      <Typography
        variant="h6"
        color={index === 0 ? 'textSecondary' : 'textPrimary'}
        key={breadcrumb}
        style={{ fontSize: '18px' }}
      >
        {breadcrumb}
      </Typography>
    ))}
  </BreadcrumbsMUI>
);

export default Breadcrumbs;
