import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import DevError from 'containers/DevError';
import Apollo from 'containers/Apollo';
import Router from 'router/Router';
import { AuthProvider } from 'hooks/useAuth';
import './App.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  attachStacktrace: true,
  release: 'platform-frontend@1.2.3',
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0.2,
});

function ErrorFallback({ error, resetError }: any) {
  if (error.name === 'ChunkLoadError') {
    window.location.reload();
    return <div />;
  }
  return <DevError onButtonClick={resetError} />;
}

const App: FC = () => (
  <Apollo>
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <BrowserRouter>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </Apollo>
);

export default Sentry.withProfiler(App);
