import { FC } from 'react';
import { Box, Typography, alpha, styled } from '@mui/material';

const DEFAULT_COLOR = '#344054';

type LabelCountBoxProps = {
  label: string;
  count: number;
  singularCountLabel: string;
  color: string;
};

const CountBox = styled(Box)<{ color: string; backgroundColor: string }>(({ color, backgroundColor }) => ({
  minWidth: '70px',
  height: '30px',
  display: 'flex',
  padding: '0 10px',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${alpha(color, 0.3)}`,
  borderRadius: '20px',
  backgroundColor,
}));

const LabelCountBox: FC<LabelCountBoxProps> = ({ label, count = 0, singularCountLabel, color }) => {
  const adjustedCountLabel = count === 1 ? singularCountLabel : `${singularCountLabel}s`;
  const backgroundColor = color ? alpha(color, 0.1) : 'white';
  const checkedColor = color || DEFAULT_COLOR;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="h6" sx={{ fontWeight: '600', marginRight: '15px' }}>
        {label}
      </Typography>
      <CountBox backgroundColor={backgroundColor} color={checkedColor}>
        <Typography variant="caption" sx={{ fontWeight: '600' }} color={color}>
          {`${count} ${adjustedCountLabel}`}
        </Typography>
      </CountBox>
    </Box>
  );
};

export default LabelCountBox;
