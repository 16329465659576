import { FC, ReactNode } from 'react';
import { Tab as MuiTab } from '@mui/material';

type TabProps = {
  label: string | ReactNode;
};

const Tab: FC<TabProps> = ({ label, ...props }) => {
  return (
    <MuiTab
      sx={{
        width: '100%',
        textTransform: 'none',
        borderRadius: '8px',
        '&.Mui-selected': { backgroundColor: '#DAEBFB' },
      }}
      label={label}
      {...props}
    />
  );
};

export default Tab;
