import { FC, ReactNode } from 'react';
import { Drawer } from '@mui/material';
import { ORGANIZATION_DRAWER_TOP_MARGIN, ORGANIZATION_DRAWER_WIDTH } from 'constants/componentSizes';

type OrganizationDrawerProps = {
  open: boolean;
  children: ReactNode;
  anchor: 'left' | 'right';
};

const OrganizationDrawer: FC<OrganizationDrawerProps> = ({ open, children, anchor }) => {
  return (
    <Drawer
      sx={{
        width: ORGANIZATION_DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: ORGANIZATION_DRAWER_WIDTH,
          height: `calc(100vh - ${ORGANIZATION_DRAWER_TOP_MARGIN}px)`,
          top: ORGANIZATION_DRAWER_TOP_MARGIN,
        },
      }}
      variant="persistent"
      anchor={anchor}
      open={open}
    >
      {children}
    </Drawer>
  );
};

export default OrganizationDrawer;
