import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

type BoxWithDecorativePatternProps = {
  children: ReactNode;
};

const BoxWithDecorativePattern: FC<BoxWithDecorativePatternProps> = ({ children }) => (
  <Box
    width="100%"
    maxWidth="780px"
    minHeight="620px"
    sx={{
      background: `url(${process.env.REACT_APP_ASSETS_URL}/backgrounds/decorative-pattern.svg)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      textAlign: 'center',
      margin: '0 auto',
    }}
  >
    {children}
  </Box>
);

export default BoxWithDecorativePattern;
