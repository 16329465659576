import { forwardRef } from 'react';
import { makeStyles } from '@mui/styles';
import { FormHelperText, Stack, TextareaAutosize, Theme } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles(({ palette }: Theme) => ({
  textarea: {
    width: '100%',
    resize: 'vertical',
    padding: '18.5px 14px',
    fontSize: '0.9em',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    '&:focus': {
      borderColor: '#3f51b5',
      outline: 'none',
    },
  },
  withError: {
    borderColor: palette.error.main,
  },
  errotText: {
    color: palette.error.main,
  },
}));

const Textarea = forwardRef(({ error, ...props }: any, ref: any) => {
  const { textarea, withError, errotText } = useStyles();

  const hasError = !!error;

  return (
    <Stack sx={{ width: '100%' }}>
      <TextareaAutosize ref={ref} className={clsx(textarea, { [withError]: hasError })} minRows={3} {...props} />
      {hasError && <FormHelperText className={errotText}>{error}</FormHelperText>}
    </Stack>
  );
});

export default Textarea;
