import React, { ReactNode, createContext } from 'react';
import { OptionsObject, useSnackbar, SnackbarProvider } from 'notistack';

interface Notification {
  message: string;
  severity?: 'error' | 'success' | 'warning' | 'info';
  duration?: number;
}

const NotificationContext = createContext(null);

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {

  return (
    <NotificationContext.Provider value={null}>
      <SnackbarProvider
        style={{ fontWeight:'bold'}}
        maxSnack={4}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        dense
        preventDuplicate
      >
        {children}
      </SnackbarProvider>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showNotification = ({ message, severity = 'info', duration = 2000 }: Notification) => {
    const options: OptionsObject = { variant: severity, autoHideDuration: duration };
    enqueueSnackbar(message, options);
  };

  const showGeneralError = () => {
    enqueueSnackbar("An Error has occurred, Please try again later", { variant: "error", autoHideDuration: 2000 });
  };

  return { showNotification, showGeneralError };
};
