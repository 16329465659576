import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Avatar } from 'components/atoms';

type UserAvatarProps = {
  firstName: string;
  lastName: string;
  position?: string;
  profileImage?: string;
};

const UserAvatar: FC<UserAvatarProps> = ({ firstName, lastName, position, profileImage }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Avatar firstName={firstName} lastName={lastName} src={profileImage} />

    <Stack flex={1}>
      <Typography ml={3} variant="body2" sx={{ fontWeight: '600' }}>
        {firstName} {lastName}
      </Typography>

      {position && (
        <Typography ml={3} mt={0.5} variant="body2">
          {position}
        </Typography>
      )}
    </Stack>
  </Box>
);

export default UserAvatar;
