import { FC } from 'react';
import { ButtonProps, CircularProgress, Button as MuiButton } from '@mui/material';

type CustomButtonProps = ButtonProps & {
  loading?: boolean;
  disabled?: boolean;
};

const Button: FC<CustomButtonProps> = ({ loading, variant = 'contained', disabled, children, ...props }) => (
  <MuiButton
    variant={variant}
    disabled={loading || disabled}
    endIcon={loading && <CircularProgress size={24} />}
    {...props}
  >
    {children}
  </MuiButton>
);

export default Button;
