import { Box, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const LoadingSkeleton = () => (
  <Box paddingTop={1}>
    <Typography component="div" variant="h2">
      <Skeleton animation="wave" />
    </Typography>
    <Skeleton style={{ marginTop: 12 }} animation="wave" variant="circular" width="100%" height={20} />
    <Skeleton style={{ marginTop: 12 }} animation="wave" variant="circular" width="100%" height={20} />
    <Skeleton style={{ marginTop: 12 }} animation="wave" variant="circular" width="100%" height={20} />
    <Skeleton style={{ marginTop: 12 }} animation="wave" variant="circular" width="75%" height={20} />
  </Box>
);

export default LoadingSkeleton;
