import { FC, lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import PublicRoute from 'components/PublicRoute/PublicRoute';
import PageLoader from 'components/Loader/PageLoader';
import DefaultLayout from 'layout/DefaultLayout';
import ContentLayout from 'layout/ContentLayout';

const Login = lazy(() => import(/* webpackChunkName: "Login" */ 'scenes/Auth/LoginScreen'));
const Authentication = lazy(() => import(/* webpackChunkName: "Authentication" */ 'scenes/Auth'));
const CompanyRegistration = lazy(
  () => import(/* webpackChunkName: "CompanyRegistration" */ 'scenes/Auth/CompanyRegistrationScreen'),
);
const ForgotPassword = lazy(() => import(/* webpackChunkName: "ForgotPassword" */ 'scenes/Auth/ForgotPasswordScreen'));
const ResetPassword = lazy(() => import(/* webpackChunkName: "ResetPassword" */ 'scenes/Auth/ResetPasswordScreen'));

const PasswordUpdateScreen = lazy(
  () => import(/* webpackChunkName: "PasswordUpdateScreen" */ 'scenes/Auth/PasswordUpdateScreen'),
);

const Settings = lazy(() => import(/* webpackChunkName: "Settings" */ 'scenes/Settings'));

const SelectFactoryDocument = lazy(
  () => import(/* webpackChunkName: "SelectFactoryDocument" */ 'scenes/DocumentFactory/SelectDocument'),
);
const SelectFactoryMethod = lazy(
  () => import(/* webpackChunkName: "SelectFactoryMethod" */ 'scenes/DocumentFactory/SelectMethod'),
);
const AiConverter = lazy(() => import(/* webpackChunkName: "AiConverter" */ 'scenes/DocumentFactory/Converter'));
const DataRoomOverview = lazy(
  () => import(/* webpackChunkName: "DataRoomOverview" */ 'scenes/Dataroom/Overview/DataRoomOverview'),
);
const TemplateList = lazy(() => import(/* webpackChunkName: "TemplateList" */ 'scenes/DocumentFactory/TemplateList'));
const DocumentViewer = lazy(() => import(/* webpackChunkName: "DocumentViewer" */ 'scenes/DocumentViewer'));
const HelpCenter = lazy(() => import(/* webpackChunkName: "HelpCenter" */ 'scenes/HelpCenter'));
const Interview = lazy(() => import(/* webpackChunkName: "Interview" */ 'scenes/Interview/Interview'));
const DefinitionWizard = lazy(
  () => import(/* webpackChunkName: "DefinitionWizard" */ 'scenes/Wizard/Definitions/Definitions'),
);
const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ 'scenes/Dashboard/Dashboard'));

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ 'scenes/NotFound/NotFound'));

const Organization = lazy(() => import(/* webpackChunkName: "Organization" */ 'scenes/Organization'));

const Router: FC = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route
          path="login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="signup"
          element={
            <PublicRoute>
              <Authentication />
            </PublicRoute>
          }
        />
        <Route
          path="company-registration"
          element={
            <PublicRoute>
              <CompanyRegistration />
            </PublicRoute>
          }
        />
        <Route
          path="forgot-password"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path="reset-password"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="change-password"
          element={
            <PublicRoute>
              <PasswordUpdateScreen />
            </PublicRoute>
          }
        />

        <Route element={<DefaultLayout />}>
          <Route
            path="organization"
            element={
              <ProtectedRoute>
                <Organization />
              </ProtectedRoute>
            }
          />
          <Route element={<ContentLayout />}>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="template-list"
              element={
                <ProtectedRoute>
                  <TemplateList />
                </ProtectedRoute>
              }
            />
            <Route
              path="data-room"
              element={
                <ProtectedRoute>
                  <DataRoomOverview />
                </ProtectedRoute>
              }
            />

            <Route
              path="help-center"
              element={
                <ProtectedRoute>
                  <HelpCenter />
                </ProtectedRoute>
              }
            />
            <Route
              path="select-document-type"
              element={
                <ProtectedRoute>
                  <SelectFactoryDocument />
                </ProtectedRoute>
              }
            />
            <Route
              path="select-creation-method/:method?"
              element={
                <ProtectedRoute>
                  <SelectFactoryMethod />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route
            path="settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />

          <Route path="document/*">
            <Route
              path=":documentId/interview"
              element={
                <ProtectedRoute>
                  <Interview />
                </ProtectedRoute>
              }
            />
            <Route
              path=":documentId/:finished?"
              element={
                <ProtectedRoute>
                  <DocumentViewer />
                </ProtectedRoute>
              }
            />
            <Route
              path="definition-wizard/:documentId/"
              element={
                <ProtectedRoute>
                  <DefinitionWizard />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="ai-conversion/:eventId"
            element={
              <ProtectedRoute>
                <AiConverter />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
