import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import NavigationBar from 'components/NavigationBar';

const Main: FC = () => {
  return (
    <>
      <NavigationBar />
      <Outlet />
    </>
  );
};

export default Main;
