import { FC } from 'react';
import { Box, Typography, alpha, styled } from '@mui/material';

enum StatusType {
  Active = 'Active',
  Inactive = 'Inactive',
  Request = 'Request',
}

type StatusItemProps = {
  status: StatusType;
};

const Container = styled(Box)<{ color: string }>(({ color }) => ({
  width: '80px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '6px',
  border: `1px solid ${alpha(color, 0.3)}`,
  borderRadius: '5px',
  backgroundColor: alpha(color, 0.1),
}));

const Dot = styled('span')({
  display: 'inline-block',
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  marginRight: '7px',
});

const statusColors = {
  [StatusType.Active]: '#17B26A',
  [StatusType.Inactive]: '#2E90FA',
  [StatusType.Request]: '#F79009',
};

const getColor = (status: StatusType) => statusColors[status] || 'black';

const StatusItem: FC<StatusItemProps> = ({ status }) => {
  if (!status) return null;

  const statusColor = getColor(status);

  return (
    <Container color={statusColor}>
      <Dot style={{ backgroundColor: statusColor }} />
      <Typography variant="caption" sx={{ fontWeight: '600' }} color={statusColor}>
        {StatusType[status]}
      </Typography>
    </Container>
  );
};

export default StatusItem;
