const USER_AVATAR_COLORS = [
  '#0F4C81',
  '#2151C5',
  '#6F2DBD',
  '#127D39',
  '#1B7F9D',
  '#AD5400',
  '#590F6C',
  '#1C2A4B',
  '#C91B18'
];

export default USER_AVATAR_COLORS;
