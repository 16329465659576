import { makeVar } from '@apollo/client';

export type EditorTypeTypes = 'TEMPLATE' | 'SMART' | 'DEFINITION_WIZARD' | '' | null;

export const Titles: Record<string, string> = {
  '/': 'Dashboard',
  '/settings': 'Settings',
  '/data-room': 'Data Room',
  '/organization': 'Organization',
  '/help-center': 'HELP CENTER',
};

// App Header Var
export interface IAppHeaderVar {
  refreshHeader?: boolean;
  title?: string;
  documentName?: string;
  versionName?: string;
  editorType?: EditorTypeTypes;
  isReadOnly?: boolean;
  readOnlyDate?: string;
  isSignature?: boolean;
  withAvatar?: boolean;
  documentDetailsOpen?: boolean;
  creationPath?: string;
  organization?: string;
}

export const DefaultAppHeaderVar = {
  title: '',
  documentName: '',
  versionName: '',
};

export const AppHeaderVar = makeVar<IAppHeaderVar>(DefaultAppHeaderVar);

export const toggleDocumentDetails = () =>
  AppHeaderVar({
    ...AppHeaderVar(),
    documentDetailsOpen: !AppHeaderVar().documentDetailsOpen,
  });

export const refreshAppHeader = (location: string) => AppHeaderVar({ title: Titles[location] ?? '' });

export const updateAppHeader = (propsToUpdate: any) => AppHeaderVar({ ...AppHeaderVar(), ...propsToUpdate });

export const setAppHeader = (props: IAppHeaderVar) => AppHeaderVar(props);

export const setCreationPath = (isTemplate: boolean) =>
  AppHeaderVar({
    creationPath: isTemplate ? 'template-import' : 'smart-import',
  });

export const cleanHeader = () => AppHeaderVar(DefaultAppHeaderVar);
