import { ChangeEvent, FC, ReactNode, useRef } from 'react';
import { Button } from '@mui/material';
import { AttachFile } from '@mui/icons-material';

type SingleFileUploadProps = {
  handleSelectFile: (event: ChangeEvent<HTMLInputElement>) => void;
  buttonType?: 'text' | 'outlined' | 'contained';
  buttonText: string;
  acceptedFileTypes: string;
  icon?: ReactNode;
  className?: string;
};

const SingleFileUpload: FC<SingleFileUploadProps> = ({
  handleSelectFile,
  buttonType,
  buttonText,
  acceptedFileTypes,
  icon,
  className,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    // Reset the value of the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <label htmlFor="raised-button-file">
      <input
        ref={fileInputRef}
        accept={acceptedFileTypes}
        id="raised-button-file"
        onChange={handleSelectFile}
        style={{ display: 'none' }}
        type="file"
      />
      <Button
        component="span"
        variant={buttonType}
        color="primary"
        startIcon={icon || <AttachFile />}
        fullWidth
        className={className}
        onClick={handleButtonClick}
      >
        {buttonText}
      </Button>
    </label>
  );
};

export default SingleFileUpload;
