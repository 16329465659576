import { Box, Typography, lighten, styled } from '@mui/material';
import { FC } from 'react';

const DEFAULT_COLOR = '#344054';

const Container = styled(Box)<{ color: string; backgroundColor: string }>(({ color, backgroundColor }) => ({
  minHeight: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'flex-start',
  border: `1px solid ${lighten(color, 0.3)}`,
  borderRadius: '10px',
  backgroundColor,
  marginRight: '10px',
}));

type OrganizationTeamLabelProps = {
  teamLabel: string;
  color?: string;
};

const OrganizationTeamLabel: FC<OrganizationTeamLabelProps> = ({ color, teamLabel }) => {
  const backgroundColor = color ? lighten(color, 0.9) : 'white';
  const checkedColor = color || DEFAULT_COLOR;
  const containerWidth = teamLabel.length * 10 + 20;

  return (
    <Container width={containerWidth} backgroundColor={backgroundColor} color={checkedColor}>
      <Typography variant="caption" sx={{ fontWeight: '600' }} color={color}>
        {teamLabel}
      </Typography>
    </Container>
  );
};

export default OrganizationTeamLabel;
