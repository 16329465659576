import { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';

const PageLoader: FC = () => {
  return (
    <Box position="fixed" display="flex" alignItems="center" justifyContent="center" top={0} left={0} right={0} bottom={0}>
      <CircularProgress />
    </Box>
  );
};

export default PageLoader;
